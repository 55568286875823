import { useDispatch } from "react-redux";
import { addMasterDataEndPoint, getAllMasterData, getEndPoint } from "./utils";
import { ContactType } from "./constants";
import axios from "axios";
import { setFiscalYearsData } from "./contactReducer";
import { useEffect, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import notify from "../../services/toasterService";
import moment from "moment";

const FiscalYearAddScreen = (props: any) => {
  const { setAddScreen } = props;
  const [templateInfo, setTemplateInfo] = useState<any[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [stepsShowFlag, setStepsShowFlag] = useState(true);
  const [calendarName, setCalendarName] = useState("");
  const [myCalenderName, setMyCalenderName] = useState("");
  const [myFiscalYearStartDate, setMyFiscalYearStartDate] = useState("");
  const [calendarStartDate, setCalendarStartDate] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [basedOnMonthEnding, setBasedOnMonthEnding] = useState(Boolean);
  const [basedOnMonthStarting, setBasedOnMonthStarting] = useState(Boolean);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    calendarNameError: "",
    fiscalYearDateError: "",
    fyMonthError: "",
  });
  let calendarNameError = "";
  let fiscalYearDateError = "";
  let fyMonthError = "";
  useEffect(() => {
    handleGetFiscaltemplate();
  }, []);
  const tabName: any = "FiscalYearTemplate";
  const handleGetFiscaltemplate = () => {
    const endpoint = getAllMasterData(tabName);
    handleGetTemaple(endpoint);
  };
  const handleGetTemaple = async (endpoint: string) => {
    const result = await axios.get(endpoint);
    if (result && result.data) {
      setTemplateInfo([result.data]);
    } else {
    }
  };
  const handleRadioChange = (e: any, id: any) => {
    let radioValue = e.target.checked;
    setSelectedTemplateId(id);
    if (radioValue) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };
  const handleCancelFiscalYear = () => {
    setAddScreen(false);
  };
  const handleStepOneContinue = () => {
    setStepsShowFlag(false);
  };
  const handleBackFirstStep = () => {
    setStepsShowFlag(true);
    // var a = document.getElementById('radioFiscalYearTempContent') as HTMLInputElement;
    // console.log(a.checked);
  };
  const handleChangeCalendarName = (e: any) => {
    setCalendarName(e.target.value);
    validate();
  };
  const handleChangeFiscalYearStartDate = (e: any) => {
    setCalendarStartDate(e.target.value);
    validate();
  };
  const handleChangeFisclaYearBased = (e: any) => {
    if (e.target.value === "endingMonth") {
      setBasedOnMonthEnding(true);
      setBasedOnMonthStarting(false);
      validate();
    } else {
      setBasedOnMonthEnding(false);
      setBasedOnMonthStarting(true);
      validate();
    }
  };
  const validate = (field?: any, value?: any) => {
    if (calendarName === "") {
      calendarNameError = "Required Field";
      setMyCalenderName('1px solid red')
    } else {
      setMyCalenderName('')
    }
    if (calendarStartDate === "") {
      fiscalYearDateError = "Required Field";
      setMyFiscalYearStartDate('1px solid red')
    } else {
      setMyFiscalYearStartDate('')
    }
    if (!basedOnMonthEnding && !basedOnMonthStarting) {
      fyMonthError = "Required Field";
    }
    if (calendarNameError || fiscalYearDateError || fyMonthError) {
      setErrors({ calendarNameError, fiscalYearDateError, fyMonthError });
      return false;
    }
    return true;
  };
  const body = {
    active: true,
    basedOnMonthEnding: basedOnMonthEnding,
    basedOnMonthStarting: basedOnMonthStarting,
    calendarName: calendarName,
    calendarYear: moment(calendarStartDate).year(),
    deleted: false,
    fiscalYearTemplateId: selectedTemplateId,
    startDate: calendarStartDate,
  };
  const tabNameForAdd: any = ContactType.FISCAL_YEAR_SETUP;
  const handleAddFiscalYearCal = () => {
    var mySelect = document.getElementById(
      "txtCalendarName"
    ) as HTMLInputElement;
    var startDate = document.getElementById("txtStartDate") as HTMLInputElement;
    // if(mySelect.value === ''){
    //   notify("Calendar Name required","warning");
    // }
    // else if(startDate.value === ''){
    //   notify("Date required","warning");
    // }
    if (validate()) {
      //  if(!basedOnMonthEnding  && !basedOnMonthStarting){
      //   notify("Please select Starting or Ending date","warning");
      //  }
      //  else{
      const endpoint = addMasterDataEndPoint(tabNameForAdd);
      const getEndpoint = getEndPoint(tabNameForAdd);
      handleMasterDataChange(endpoint, getEndpoint);
      //}
    }
  };
  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    const result = await axios
      .post(endpoint, body)
      .then(async (data) => {
        if (data && data.data) {
          setAddScreen(false);
          const fiscalYearEndPoint = getAllMasterData("FiscalYear");
          const fiscalYearresult = await axios
            .get(fiscalYearEndPoint)
            .catch((err) => err);
          dispatch(setFiscalYearsData(fiscalYearresult.data));
          notify("Fiscal Year Added Succesfully", "success");
        } else {
          notify("Failed to add Fiscal Year", "error");
        }
        // const getresult = await axios.get(getEndpoint);
        // dispatch(setCurrentContact(getresult.data));
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
  };

  let [stepperindexvalue, setStepperIndexValue] = useState(1);

  const goToNextStep = (index: number) => {
    if (index === 1) {
      setStepperIndexValue(1);
    } else if (index === 2) {
      setStepperIndexValue(2);
    }
  };

  return (
    <>
    <div className="StepperControl">
        <div className="col-6 pl-2 pt-0 mt-auto mb-auto">
          <div className="page-header--col col--steps">
            <ul className="list--steps pt-3 mb-0 pointer">
              <li
                className={` ${stepperindexvalue === 1 ? "active" : ""}`}
                onClick={(e) => goToNextStep(1)}
              >
                <strong className="steps--number">1</strong>
                <p className="steps--label">TEMPLATE</p>
              </li>
              <li
                className={` ${stepperindexvalue === 2 ? "active" : ""}`}
                onClick={(e) => goToNextStep(2)}
              >
                <strong className="steps--number">2</strong>
                <p className="steps--label">DETAILS</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {stepperindexvalue === 1 ? (
        <>
          {/* <div className="row col-sm-12">
            <span className="fiscalYearTempStepsHeading">
              Step 1: Select Template
            </span>
          </div> */}
          {templateInfo &&
            templateInfo[0]?.map((info: any) => (
              <div className="row col-sm-12 mt-1">
                <span className="templateHeaderNameFiscal">
                  {info.templateHeaderName}
                </span>
                <br />

                {info &&
                  info.fiscalYearTemplatesList?.map((item: any) => (
                    <div className="templateContentFiscalYear">
                      <input
                        type="radio"
                        id="radioFiscalYearTempContent"
                        disabled={!item.active}
                        className={
                          item.active ? "enableRadioBtn" : "disableRadioBtn"
                        }
                        onChange={(e) => handleRadioChange(e, info.id)}
                      />
                      <span className="templateNameFiscal" style={{fontSize: '15px'}}>
                        {item.templateName}
                      </span>
                      <span className="templateDescFiscal" style={{fontSize: '14px'}}>
                        {item.templateDescription}
                        <br />
                      </span>
                    </div>
                  ))}
              </div>
            ))}

          <div className="row col-sm-12 mt-5">
            <div className="col-sm-2">
              <ButtonComponent
                cssClass="e-control e-btn e-lib eg-btn-primary-link e-flat mt-3"
                onClick={handleCancelFiscalYear}
              >
                CANCEL
              </ButtonComponent>
            </div>
            <div className="col-sm-10 p-0">
              <ButtonComponent
                className={
                  buttonDisabled ? "btnContinueDisabled" : "btnAddInPopups"
                }
                id="btnContinue"
                disabled={buttonDisabled}
                onClick={() => goToNextStep(2)}
                style={{float: 'right', border: 'none', width: '168px', height: '48px'}}
              >
                CONTINUE
              </ButtonComponent>
            </div>
          </div>
        </>
      ) : (
        <div>
          {/* <div className="row ml-2rem mb-1rem fiscal-yearpopup">
            <span className="fiscalYearTempStepsHeading">
              Step 2: Fill in details
            </span>
          </div> */}
          <div className="FY-pop-content">
          <div className="row col-sm-12">
            <div className="col-sm-6">
              <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>Template</span>
                </div>
                <div className="col-sm-12">
                <span className="templatename" style={{marginTop: '8px'}}>4-4-5</span>
              </div>
            
            
              <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>
                  Calendar Name<span className="requiredStar">*</span>
                </span>
                {errors.calendarNameError ? (
                  <span className="text-danger" style={{float: 'right', marginTop: '10px'}}>
                    {errors.calendarNameError}
                  </span>
                ) : (
                  ""
                )}
                <input
                  type="text"
                  id="txtCalendarName"
                  className="formcontrol"
                  onChange={(e) => handleChangeCalendarName(e)}
                  style={{ border: myCalenderName }}
                />
              </div>
            
            </div>
            <div className="col-sm-6">            
              <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>
                  Fiscal Year Start Date<span className="requiredStar">*</span>
                </span>
                {calendarStartDate === "" ? (
                  <span className="text-danger" style={{float: 'right', marginTop: '10px'}}>
                    {errors.fiscalYearDateError}
                  </span>
                ) : (
                  ""
                )}
                <input
                  type="date"
                  id="txtStartDate"
                  className="formcontrol"
                  onChange={(e) => handleChangeFiscalYearStartDate(e)}
                  style={{ border: myFiscalYearStartDate }}
                />
              </div>
            
            
              <div className="col-sm-12">
                <span className="lblClass" style={{float: 'left'}}>
                  Fiscal Year Based On<span className="requiredStar">*</span>
                </span>
                {!basedOnMonthEnding && !basedOnMonthStarting ? (
                  <span className="text-danger" style={{float: 'right', marginTop: '13px'}}>{errors.fyMonthError}</span>
                ) : (
                  ""
                )}
              </div>
              <div
                className="col-sm-12"
                onChange={(e) => handleChangeFisclaYearBased(e)}
              >
                <input
                  type="radio"
                  value="endingMonth"
                  name="radioFiscalYearBased"
                />{" "}
                Ending Month
                <br />
                <input
                  type="radio"
                  value="startingMonth"
                  name="radioFiscalYearBased"
                />{" "}
                Starting Month <br />
              </div>
            
            </div>
          </div>
          </div>
          <div className="row col-sm-12" style={{marginTop: '20rem'}}>
            <div className="col-sm-2">
              <span
                onClick={handleCancelFiscalYear}
                className="goBackBtnFiscalYear"
                style={{lineHeight: '40px'}}
              >
                CANCEL
              </span>
            </div>
            <div className="col-sm-10 p-0">
              <ButtonComponent
                cssClass="p-15 eg-btn-secondary"
                onClick={() => goToNextStep(1)}
                style={{marginLeft: '259px'}}
              >
                Go Back
              </ButtonComponent>
              <ButtonComponent
                cssClass="p-15 eg-btn-primary"
                onClick={handleAddFiscalYearCal}
                style={{marginLeft: '10px'}}
              >
                ADD CALENDAR
              </ButtonComponent>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default FiscalYearAddScreen;
