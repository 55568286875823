import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import addIcon from '../../assets/add.png';
import {
    addMasterDataEndPoint,
    getEndPoint,
    getStatesByCountry,
} from './utils';
import userIcon from '../../assets/userIcon.png';
import closeIcon from '../../assets/iconClose.png';
import {
    setCurrentContact,
    setMasterDataAddImage,
    setStatesAddrDataOne,
    setStatesAddrDataTwo,
    setStatesData,
} from './contactReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ContactType } from './constants';
import notify from '../../services/toasterService';
import CountryDropdown from '../ContactsDetailsPanel/mobile-field-component';
import editIcon from '../../assets/EditRuleExcep.png';
import AddFileUploadScreen from '../accessMgmt/addScreen/fileUpload';
import { getStatesByCountryCode } from '../accessMgmt/utils';
import { CiCirclePlus } from 'react-icons/ci';
import { RxCross2 } from 'react-icons/rx';

const CarrierAddScreen = (props: any) => {
    const { setAddScreen } = props;
    useEffect(() => {
        getStatesList('USA');
        getStatesListAddr('USA');
        getStatesListAddrTwo('USA');
    }, []);
    const [showAltAddressInfo, setShowAltAddressInfo] = useState(false);
    const [showAltAddressInfoOne, setShowAltAddressInfoOne] = useState(false);
    const [selectedImage, setSelectedImage] = useState();
    const dispatch = useDispatch();
    const [carrierName, setCarrierName] = useState('');
    const [carrierEmail, setCarrierEmail] = useState('');
    const [carrierPhone, setCarrierPhone] = useState('');
    const [carrierAltPhone, setCarrierAltPhone] = useState('');
    const [carrierFax, setCarrierFax] = useState('');
    const [carrierEndaDate, setCarrierEndaDate] = useState('');
    const [carrierAddressStreet, setCarrierAddressStreet] = useState('');
    const [carrierAddressCity, setCarrierAddressCity] = useState('');
    const [carrierAddressState, setCarrierAddressState] = useState('');
    const [carrierAddressZip, setCarrierAddressZip] = useState('');
    const [carrierAddressCountry, setCarrierAddressCountry] = useState(
        'United States of America'
    );
    const [carrierCountryCodePhone, setCarrierCountryCodePhone] = useState('');
    const [carrierCountryCodeAltPhone, setCarrierCountryCodeAltPhone] =
        useState('');
    const [carrierCountryCodeFax, setCarrierCountryCodeFax] = useState('');

    const [carrierAltAddressStreetOne, setCarrierAltAddressStreetOne] =
        useState('');
    const [carrierAltAddressCityOne, setCarrierAltAddressCityOne] =
        useState('');
    const [carrierAltAddressStateOne, setCarrierAltAddressStateOne] =
        useState('');
    const [carrierAltAddressZipOne, setCarrierAltAddressZipOne] = useState('');
    const [carrierAltAddressCountryOne, setCarrierAltAddressCountryOne] =
        useState('');

    const [carrierAltAddressStreetTwo, setCarrierAltAddressStreetTwo] =
        useState('');
    const [carrierAltAddressCityTwo, setCarrierAltAddressCityTwo] =
        useState('');
    const [carrierAltAddressStateTwo, setCarrierAltAddressStateTwo] =
        useState('');
    const [carrierAltAddressZipTwo, setCarrierAltAddressZipTwo] = useState('');
    const [carrierAltAddressCountryTwo, setCarrierAltAddressCountryTwo] =
        useState('');

    const [carrierLanguage, setCarrierLanguage] = useState('en-US');
    const [carrierCurrencyCode, setCarrierCurrencyCode] = useState('US Dollar');
    const [carrierTimeZone, setCarrierTimeZone] = useState(
        'Eastern Standard Time'
    );
    const [addButtonDisabledCarrier, setAddButtonDisabledCarrier] =
        useState(true);

    const [myNameCarrier, setMyNameCarrier] = useState('');

    const [altAddressAdd, setAltAddressAdd] = useState([
        { street: '', city: '', state: '', zipcode: '', country: '' },
    ]);

    const [defautlCountry, setDefaultCountry] = useState(
        'United States of America'
    );

    const [isCarrierNextDisabeled, setisCarrierNextDisabeled] = useState(true);

    const [errors, setErrors] = useState({
        nameError: '',
        emailError: '',
        phoneError: '',
        phoneCountryCodeError: '',
        altPhoneCountryCodeError: '',
        faxCountryCodeError: '',
        altPhoneError: '',
        languageError: '',
        currencyCodeError: '',
        timezoneError: '',
        zipError: '',
        faxError: '',
        zipErrorOne: '',
        zipErrorTwo: '',
        countryError: '',
    });
    let nameError = '';
    let emailError = '';
    let phoneError = '';
    let phoneCountryCodeError = '';
    let altPhoneCountryCodeError = '';
    let faxCountryCodeError = '';
    let altPhoneError = '';
    let languageError = '';
    let currencyCodeError = '';
    let timezoneError = '';
    let zipError = '';
    let faxError = '';
    let zipErrorOne = '';
    let zipErrorTwo = '';
    let countryError = '';
    const currencyData = useSelector(
        (state: RootState) => state.contactReducer?.currencyData
    );
    const timezoneData = useSelector(
        (state: RootState) => state.contactReducer?.timezoneData
    );
    const languageData = useSelector(
        (state: RootState) => state.contactReducer?.laungageData
    );
    const countryInfo = useSelector(
        (state: RootState) => state.contactReducer?.countryData
    );
    const countryCodeInfo = useSelector(
        (state: RootState) => state.contactReducer?.countryCodePhoneData
    );
    const statesAddInfoOne = useSelector(
        (state: RootState) => state.contactReducer?.statesAddrDataOne
    );
    const statesAddrInfoTwo = useSelector(
        (state: RootState) => state.contactReducer?.statesAddrDataTwo
    );
    const statesInfo = useSelector(
        (state: RootState) => state.contactReducer?.statesData
    );

    const mobileWithFlag = useSelector(
        (state: RootState) =>
            state.contactReducer?.selectedMobileWithCountryFlag
    );
    const altMobileWithFlag = useSelector(
        (state: RootState) =>
            state.contactReducer?.selectedAltMobileWithCountryFlag
    );
    const faxWithFlag = useSelector(
        (state: RootState) => state.contactReducer?.selectedFaxWithCountryFlag
    );
    const phoneCountryCode = useSelector(
        (state: RootState) => state.contactReducer?.selectedCountryCodeForMobile
    );
    const AltPhoneCountryCode = useSelector(
        (state: RootState) =>
            state.contactReducer?.selectedCountryCodeForAltMobile
    );
    const faxCountryCode = useSelector(
        (state: RootState) => state.contactReducer?.selectedCountryCodeForFax
    );

    const [primaryAddress, setPrimaryAddress] = useState<any>({
        addressLine1: '',
        addressLine2: '',
        alternateAddress: false,
        city: '',
        country: '',
        state: '',
        street: '',
        zipcode: '',
        billingAddress: true,
    });

    const [addressInfo, setAddressInfo] = useState<any[]>([]);

    const imageChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
    };
    const handleAltAdress = () => {
        setShowAltAddressInfo(true);
    };
    const handleAltAdressClose = () => {
        setShowAltAddressInfo(false);
    };
    const handleAltAdressOne = () => {
        setShowAltAddressInfoOne(true);
    };
    const handleAltAdressCloseOne = () => {
        setShowAltAddressInfoOne(false);
    };
    const handleCarrierName = (e: any) => {
        setCarrierName(e.target.value);
        validate();
    };
    const handleCarrierEmail = (e: any) => {
        setCarrierEmail(e.target.value);
        validate();
        const reg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //  if(e.target.value==="") {
        //    emailError = "Email is Required";
        //  }
        if (e.target.value && reg.test(e.target.value) === false) {
            emailError = 'Email is Invalid ';
        }
        if (emailError) {
            setErrors({
                nameError,
                emailError,
                countryError: '',
                phoneError,
                altPhoneCountryCodeError,
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return false;
        } else {
            setCarrierEmail(e.target.value);
            return true;
        }
    };
    //******************** */
    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [myCarrierName, setMyCarrierName] = useState('');
    const [carrierInfoSelectFieldObj, setCarrierInfoSelectFieldObj] = useState<
        any[]
    >([
        {
            language: 'en-US',
            timezone: 'Eastern Standard Time',
            countryId: 'United States of America',
            stateId: '',
            currencyCode: 'US Dollar',
        },
    ]);

    const [carrierInfo, setCarrierInfo] = useState<any[]>([
        {
            carrierName: '',
            email: '',
            endDate: carrierEndaDate,
            phoneNumber: '',
            zipcode: '',
            addressLine1: '',
            addressLine2: '',
            alternatePhoneNumber: '',
            phoneCountryCode: phoneCountryCode,
            altPhoneCountryCode: AltPhoneCountryCode,
            faxCountryCode: faxCountryCode,
            fax: '',
        },
    ]);

    const handleCarrierTextFields = (event: any, key: any) => {
        if (key === 'zipcode') {
            if (event.target.value.length <= 5) {
                setCarrierInfo([
                    { ...carrierInfo[0], [key]: event.target.value },
                ]);
                //validateMobileUsers();
                validateCarriers();
            }
        } else {
            setCarrierInfo([{ ...carrierInfo[0], [key]: event.target.value }]);
            validateCarriers();
        }
    };

    const [carrierInfoAlt, setCarrierInfoAlt] = useState<any[]>([
        {
            zipcode: '',
            addressLine1: '',
            addressLine2: '',
        },
    ]);

    const handleCarrierTextFieldsAlt = (event: any, key: any) => {
        if (key === 'zipcode') {
            if (event.target.value.length <= 5) {
                setCarrierInfoAlt([
                    { ...carrierInfoAlt[0], [key]: event.target.value },
                ]);
                //validateMobileUsers();
            }
        } else {
            setCarrierInfoAlt([
                { ...carrierInfoAlt[0], [key]: event.target.value },
            ]);
            validateCarriers();
        }
    };

    const [carrierInfoAltAddrTwo, setCarrierInfoAltAddrTwo] = useState<any[]>([
        {
            zipcode: '',
            addressLine1: '',
            addressLine2: '',
        },
    ]);

    const handleCarrierTextFieldsAltAddrTwo = (event: any, key: any) => {
        if (key === 'zipcode') {
            if (event.target.value.length <= 5) {
                setCarrierInfoAltAddrTwo([
                    { ...carrierInfoAltAddrTwo[0], [key]: event.target.value },
                ]);
                //validateMobileUsers();
            }
        } else {
            setCarrierInfoAltAddrTwo([
                { ...carrierInfoAltAddrTwo[0], [key]: event.target.value },
            ]);
            validateCarriers();
        }
    };

    const [statesListAlt, setStateListAlt] = useState<any>([]);

    const getStatesListAddr = async (countryCode: any) => {
        const endPointUrl = getStatesByCountryCode(countryCode);
        await axios.get(endPointUrl).then(
            (res) => {
                setStateListAlt(res.data);
                // dispatch(setStatesList(res.data));
            },
            (err) => {
                notify('Failed to fetch data', 'error');
            }
        );
    };

    const [
        carrierInfoSelectFieldObjAltAddr,
        setCarrierInfoSelectFieldObjAltAddr,
    ] = useState<any[]>([
        {
            countryId: 'United States of America',
            stateId: '',
        },
    ]);
    const handleCarrierSelectFieldsAltAddr = (event: any, key: any) => {
        //const dataa = JSON.parse(event.target.value);
        if (key === 'countryId') {
            const dataa = JSON.parse(event.target.value);
            getStatesListAddr(dataa.isoCountryCode);
            setCarrierInfoSelectFieldObjAltAddr([
                {
                    ...carrierInfoSelectFieldObjAltAddr[0],
                    [key]: dataa.countryName,
                },
            ]);
            setCarrierInfo([{ ...carrierInfo[0], [key]: dataa.id }]);
        }
        // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
        if (key === 'stateId') {
            setCarrierInfoSelectFieldObjAltAddr([
                {
                    ...carrierInfoSelectFieldObjAltAddr[0],
                    [key]: event.target.value,
                },
            ]);
            // setCarrierInfo([{ ...carrierInfo[0], [key]: dataa.id }]);
        }
        validateCarriers();
    };

    const [statesListAltAddrTwo, setStateListAltAddrTwo] = useState<any>([]);

    const getStatesListAddrTwo = async (countryCode: any) => {
        const endPointUrl = getStatesByCountryCode(countryCode);
        await axios.get(endPointUrl).then(
            (res) => {
                setStateListAltAddrTwo(res.data);
                // dispatch(setStatesList(res.data));
            },
            (err) => {
                notify('Failed to fetch data', 'error');
            }
        );
    };

    const [
        carrierInfoSelectFieldObjAltAddrTwo,
        setCarrierInfoSelectFieldObjAltAddrTwo,
    ] = useState<any[]>([
        {
            countryId: 'United States of America',
            stateId: '',
        },
    ]);
    const handleCarrierSelectFieldsAltAddrTwo = (event: any, key: any) => {
        //const dataa = JSON.parse(event.target.value);
        if (key === 'countryId') {
            const dataa = JSON.parse(event.target.value);
            getStatesListAddrTwo(dataa.isoCountryCode);
            setCarrierInfoSelectFieldObjAltAddrTwo([
                {
                    ...carrierInfoSelectFieldObjAltAddrTwo[0],
                    [key]: dataa.countryName,
                },
            ]);
            setCarrierInfo([{ ...carrierInfo[0], [key]: dataa.id }]);
        }
        // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
        if (key === 'stateId') {
            setCarrierInfoSelectFieldObjAltAddrTwo([
                {
                    ...carrierInfoSelectFieldObjAltAddrTwo[0],
                    [key]: event.target.value,
                },
            ]);
            // setCarrierInfo([{ ...carrierInfo[0], [key]: dataa.id }]);
        }
        validateCarriers();
    };

    const getStatesListAltOne = async (countryCode: any, index: number) => {
        const endPointUrl = getStatesByCountryCode(countryCode);
        await axios.get(endPointUrl).then(
            (res) => {
                // setStateListAltOne(res.data);
                const adddressInfoCopy = [...addressInfo];
                adddressInfoCopy[index]['stateList'] = res.data;
                setAddressInfo(adddressInfoCopy);
                // dispatch(setStatesList(res.data));
            },
            (err) => {
                notify('Failed to fetch data', 'error');
            }
        );
    };

    const handleAddressField = (
        value: any,
        fieldName: string,
        index: number
    ) => {
        try {
            if (index === -1) {
                if (fieldName === 'billingAddress') {
                    const adddressInfoCopy = [...addressInfo];
                    adddressInfoCopy.forEach((ele: any) => {
                        ele.billingAddress = false;
                    });
                    setAddressInfo(adddressInfoCopy);
                }

                const primaryAddressCopy = { ...primaryAddress };
                primaryAddressCopy[fieldName] = value;
                setPrimaryAddress(primaryAddressCopy);
                if (fieldName === 'country') {
                    const selected = countryInfo.find(
                        (ele: any) => ele.countryName === value
                    );
                    getStatesList(selected?.isoCountryCode);
                    setPrimaryAddress({ ...primaryAddressCopy, state: '' });
                }
            } else {
                const adddressInfoCopy = [...addressInfo];

                if (fieldName === 'billingAddress') {
                    adddressInfoCopy.forEach((ele: any) => {
                        ele.billingAddress = false;
                    });
                    const primaryAddressCopy = { ...primaryAddress };
                    primaryAddressCopy[fieldName] = false;
                    setPrimaryAddress(primaryAddressCopy);
                }

                adddressInfoCopy[index][fieldName] = value;
                setAddressInfo(adddressInfoCopy);

                if (fieldName === 'country') {
                    const selected = countryInfo.find(
                        (ele: any) => ele.countryName === value
                    );
                    getStatesListAltOne(selected?.isoCountryCode, index);
                    adddressInfoCopy[index]['state'] = '';
                    setAddressInfo(adddressInfoCopy);
                }
            }
        } catch (error) {}
    };

    const addAltAddress = () => {
        try {
            setAddressInfo([
                ...addressInfo,
                {
                    addressLine1: '',
                    addressLine2: '',
                    alternateAddress: false,
                    city: '',
                    country: '',
                    state: '',
                    street: '',
                    zipcode: '',
                    billingAddress: false,
                    key: addressInfo.length + 1,
                    stateList: [],
                },
            ]);
        } catch (error) {}
    };

    const removeAltAddress = (option: any) => {
        try {
            const updatedData: any = addressInfo.filter(
                (ele) => ele.key !== option?.key
            );

            updatedData.forEach((element: any, index: number) => {
                element.key = index + 1;
            });

            setAddressInfo(updatedData);
        } catch (error) {}
    };

    const [statesList, setStateList] = useState<any>([]);

    const getStatesList = async (countryCode: any) => {
        const endPointUrl = getStatesByCountryCode(countryCode);
        await axios.get(endPointUrl).then(
            (res) => {
                setStateList(res.data);
                // dispatch(setStatesList(res.data));
            },
            (err) => {
                notify('Failed to fetch data', 'error');
            }
        );
    };

    const handleCarrierSelectFields = (event: any, key: any) => {
        //const dataa = JSON.parse(event.target.value);
        if (key === 'countryId') {
            const dataa = JSON.parse(event.target.value);
            getStatesList(dataa.isoCountryCode);
            setCarrierInfoSelectFieldObj([
                { ...carrierInfoSelectFieldObj[0], [key]: dataa.countryName },
            ]);
            setCarrierInfo([{ ...carrierInfo[0], [key]: dataa.id }]);
        }
        // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
        if (key === 'stateId') {
            setCarrierInfoSelectFieldObj([
                { ...carrierInfoSelectFieldObj[0], [key]: event.target.value },
            ]);
            // setCarrierInfo([{ ...carrierInfo[0], [key]: dataa.id }]);
        }
        if (key === 'language') {
            setCarrierInfoSelectFieldObj([
                { ...carrierInfoSelectFieldObj[0], [key]: event.target.value },
            ]);
            // setCarrierInfo([{ ...carrierInfo[0], [key]: dataa.isoLongCode }]);
        }
        if (key === 'timezone') {
            setCarrierInfoSelectFieldObj([
                { ...carrierInfoSelectFieldObj[0], [key]: event.target.value },
            ]);
            // setCarrierInfo([{ ...carrierInfo[0], [key]: dataa.timezoneName }]);
        }

        validateCarriers();
    };
    //******************* */

    const validateCarriers = () => {
        if (carrierInfo[0].carrierName === '') {
            nameError = 'Required Field';
            setMyCarrierName('1px solid red');
        } else {
            setMyCarrierName('');
            nameError = '';
        }
        if (carrierInfo[0].email !== '') {
            console.log(emailError);
            var regexFax =
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!regexFax.test(carrierInfo[0].email)) {
                console.log(emailError);
                emailError = 'Invalid Email';
                setEmailErrorMsg('Invalid Email');
            } else {
                setEmailErrorMsg('');
            }
        }
        if (carrierInfoSelectFieldObj[0].currencyCode === '') {
            currencyCodeError = 'Required Field';
            //setMyUserName("1px solid red");
        } else {
            //setMyUserName("");
        }
        if (carrierInfoSelectFieldObj[0].language === '') {
            languageError = 'Required Field';
            //setMyUserName("1px solid red");
        } else {
            //setMyUserName("");
        }
        if (carrierInfoSelectFieldObj[0].timezone === '') {
            timezoneError = 'Required Field';
            //setMyUserName("1px solid red");
        } else {
            //setMyUserName("");
        }
        if (
            //mobileUserInfo[0].firstname === "" ||
            carrierInfo[0].carrierName === '' ||
            carrierInfoSelectFieldObj[0].timezone === '' ||
            carrierInfoSelectFieldObj[0].language === '' ||
            carrierInfoSelectFieldObj[0].currencyCode === ''
            // mobileUserInfo[0].password === ""
        ) {
            setErrors({
                nameError,
                emailError,
                countryError: '',
                phoneError,
                altPhoneCountryCodeError,
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            setisCarrierNextDisabeled(true);
            return false;
        } else {
            setisCarrierNextDisabeled(false);
        }
        return true;
    };

    const handleCarrierPhone = (e: any) => {
        setCarrierPhone(e.target.value);
        validate();
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (e.target.value.match(phoneno)) {
            setCarrierPhone(e.target.value);
            phoneError = '';
            setErrors({
                nameError,
                emailError,
                countryError: '',
                phoneError,
                altPhoneCountryCodeError,
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return true;
        } else {
            phoneError = 'Phone number is invalid';
        }
        if (phoneError) {
            setErrors({
                nameError,
                emailError,
                phoneError,
                countryError: '',
                altPhoneCountryCodeError,
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return false;
        }
    };

    const handleCarrierAltPhone = (e: any) => {
        validate();
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (e.target.value.match(phoneno)) {
            setCarrierAltPhone(e.target.value);
            altPhoneError = '';
            setErrors({
                nameError,
                emailError,
                phoneError,
                countryError: '',
                altPhoneCountryCodeError,
                phoneCountryCodeError,
                altPhoneError,
                faxCountryCodeError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return true;
        } else {
            altPhoneError = 'Phone number is invalid';
        }
        if (altPhoneError) {
            setErrors({
                nameError,
                emailError,
                phoneError,
                altPhoneCountryCodeError,
                countryError: '',
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return false;
        }
    };

    const handleCarrierFax = (e: any) => {
        setCarrierFax(e.target.value);
        setErrors({
            nameError,
            emailError,
            phoneError,
            altPhoneCountryCodeError,
            phoneCountryCodeError,
            countryError: '',
            faxCountryCodeError,
            altPhoneError,
            languageError,
            currencyCodeError,
            timezoneError,
            zipError,
            faxError,
            zipErrorOne,
            zipErrorTwo,
        });
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (e.target.value.match(phoneno)) {
            setCarrierFax(e.target.value);
            faxError = '';
            setErrors({
                nameError,
                emailError,
                phoneError,
                altPhoneCountryCodeError,
                countryError: '',
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return true;
        } else {
            faxError = 'Fax is invalid';
        }
        if (faxError) {
            setErrors({
                nameError,
                emailError,
                phoneError,
                altPhoneCountryCodeError,
                countryError: '',
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return false;
        }
    };

    const handleCarrierEndDate = (e: any) => {
        setCarrierEndaDate(e.target.value);
    };

    const handleCarrierAddressStreet = (e: any) => {
        setCarrierAddressStreet(e.target.value);
        validate();
    };

    const handleCarrierAddressCity = (e: any) => {
        setCarrierAddressCity(e.target.value);
        validate();
    };

    const handleCarrierAddressState = (e: any) => {
        setCarrierAddressState(e.target.value);
        validate();
    };

    const handleCarrierAddressZip = (e: any) => {
        setCarrierAddressZip(e.target.value);
        setErrors({
            nameError,
            emailError,
            phoneError,
            altPhoneCountryCodeError,
            countryError: '',
            phoneCountryCodeError,
            faxCountryCodeError,
            altPhoneError,
            languageError,
            currencyCodeError,
            timezoneError,
            zipError,
            faxError,
            zipErrorOne,
            zipErrorTwo,
        });
        var regexFax = '^[0-9]{5}(?:-[0-9]{4})?$';
        if (e.target.value.match(regexFax)) {
            setCarrierAddressZip(e.target.value);
            zipError = '';
            setErrors({
                nameError,
                emailError,
                phoneError,
                altPhoneCountryCodeError,
                countryError: '',
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return true;
        } else {
            zipError = 'Zip code is invalid';
        }
        if (zipError) {
            setErrors({
                nameError,
                emailError,
                phoneError,
                altPhoneCountryCodeError,
                countryError: '',
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return false;
        }
    };

    const handleCarrierAddressCountry = (e: any) => {
        //setCarrierAddressCountry(e.target.value);
        const countryCode = JSON.parse(e);
        setCarrierAddressCountry(countryCode.countryName);
        const getEndpoint = getStatesByCountry(countryCode.isoCountryCode);
        handleGetStatesList(getEndpoint);
        validate();
    };
    const handleGetStatesList = async (getEndpoint: string) => {
        const getresult = await axios.get(getEndpoint);
        let cData: any = getresult.data;
        dispatch(setStatesData(cData));
    };
    const handleCarrierLanguage = (e: any) => {
        setCarrierLanguage(e.target.value);
        validate();
    };
    const handleCarrierCurrencyCode = (e: any) => {
        setCarrierCurrencyCode(e.target.value);
        validate();
    };
    const handleCarrierTimezone = (e: any) => {
        setCarrierTimeZone(e.target.value);
        validate();
    };

    const handleChangeCarrierCountryCodePhoneRC = (e: any) => {
        setCarrierCountryCodePhone(e.target.value);
        validate();
    };
    const handleChangeCarrierCountryAltPhoneRC = (e: any) => {
        setCarrierCountryCodeAltPhone(e.target.value);
    };
    const handleChangeCarrierCountryCodeFaxRC = (e: any) => {
        setCarrierCountryCodeFax(e.target.value);
    };

    const handleCarrierAltAddressStreetOne = (e: any) => {
        setCarrierAltAddressStreetOne(e.target.value);
    };

    const handleCarrierAltAddressCityOne = (e: any) => {
        setCarrierAltAddressCityOne(e.target.value);
    };

    const handleCarrierAltAddressStateOne = (e: any) => {
        setCarrierAltAddressStateOne(e.target.value);
        validate();
    };

    const handleCarrierAltAddressZipOne = (e: any) => {
        setCarrierAltAddressZipOne(e.target.value);
        setErrors({
            nameError,
            emailError,
            phoneError,
            altPhoneCountryCodeError,
            countryError: '',
            phoneCountryCodeError,
            faxCountryCodeError,
            altPhoneError,
            languageError,
            currencyCodeError,
            timezoneError,
            zipError,
            faxError,
            zipErrorOne,
            zipErrorTwo,
        });
        var regexFax = '^[0-9]{5}(?:-[0-9]{4})?$';
        if (e.target.value.match(regexFax)) {
            setCarrierAltAddressZipOne(e.target.value);
            zipErrorOne = '';
            setErrors({
                nameError,
                emailError,
                phoneError,
                altPhoneCountryCodeError,
                countryError: '',
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return true;
        } else {
            zipErrorOne = 'Zip code is invalid';
        }
        if (zipErrorOne) {
            setErrors({
                nameError,
                emailError,
                phoneError,
                altPhoneCountryCodeError,
                countryError: '',
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return false;
        }
    };

    const handleCarrierALtAddressCountryOne = (e: any) => {
        //setCarrierAltAddressCountryOne(e.target.value);
        const countryCode = JSON.parse(e.target.value);
        setCarrierAltAddressCountryOne(countryCode.countryName);
        const getEndpoint = getStatesByCountry(countryCode.isoCountryCode);
        handleGetStatesListOne(getEndpoint);
    };

    const handleGetStatesListOne = async (getEndpoint: string) => {
        const getresult = await axios.get(getEndpoint);
        let cData: any = getresult.data;
        dispatch(setStatesAddrDataOne(cData));
    };

    const handleCarrierAltAddressStreetTwo = (e: any) => {
        setCarrierAltAddressStreetTwo(e.target.value);
    };

    const handleCarrierAltAddressCityTwo = (e: any) => {
        setCarrierAltAddressCityTwo(e.target.value);
    };

    const handleCarrierAltAddressStateTwo = (e: any) => {
        setCarrierAltAddressStateTwo(e.target.value);
    };

    const handleCarrierAltAddressZipTwo = (e: any) => {
        setCarrierAltAddressZipTwo(e.target.value);
        setErrors({
            nameError,
            emailError,
            phoneError,
            altPhoneCountryCodeError,
            countryError: '',
            phoneCountryCodeError,
            faxCountryCodeError,
            altPhoneError,
            languageError,
            currencyCodeError,
            timezoneError,
            zipError,
            faxError,
            zipErrorOne,
            zipErrorTwo,
        });
        var regexFax = '^[0-9]{5}(?:-[0-9]{4})?$';
        if (e.target.value.match(regexFax)) {
            setCarrierAltAddressZipTwo(e.target.value);
            zipErrorTwo = '';
            setErrors({
                nameError,
                emailError,
                phoneError,
                altPhoneCountryCodeError,
                countryError: '',
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return true;
        } else {
            zipErrorTwo = 'Zip code is invalid';
        }
        if (zipErrorTwo) {
            setErrors({
                nameError,
                emailError,
                phoneError,
                altPhoneCountryCodeError,
                countryError: '',
                phoneCountryCodeError,
                faxCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            return false;
        }
    };

    const handleCarrierALtAddressCountryTwo = (e: any) => {
        // setCarrierAltAddressCountryTwo(e.target.value);
        const countryCode = JSON.parse(e.target.value);
        setCarrierAltAddressCountryTwo(countryCode.countryName);
        const getEndpoint = getStatesByCountry(countryCode.isoCountryCode);
        handleGetStatesListTwo(getEndpoint);
    };
    const handleGetStatesListTwo = async (getEndpoint: string) => {
        const getresult = await axios.get(getEndpoint);
        let cData: any = getresult.data;
        dispatch(setStatesAddrDataTwo(cData));
    };
    const handleAltAddChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const list = [...altAddressAdd, e.target.value];
        //list[index][name] = value;
        setAltAddressAdd(list);
        console.log('alt', altAddressAdd);
    };
    const handleAltAdressOneLatest = () => {
        setAltAddressAdd([
            ...altAddressAdd,
            { street: '', city: '', state: '', zipcode: '', country: '' },
        ]);
    };
    // const body = {
    //   active: true,
    //   address: [
    //     {
    //       active: true,
    //       addressLine1: "",
    //       addressLine2: "",
    //       archieved: true,
    //       alternateAddress  : false,
    //       city: carrierAddressCity,
    //       country: carrierAddressCountry,
    //       deleted: false,
    //       state: carrierAddressState,
    //       street: carrierAddressStreet,
    //       zipcode: carrierAddressZip,
    //     },
    //     {
    //       active: true,
    //       addressLine1: "",
    //       addressLine2: "",
    //       archieved: true,
    //       alternateAddress: true,
    //       city: carrierAltAddressCityOne,
    //       country: carrierAltAddressCountryOne,
    //       deleted: false,
    //       state: carrierAltAddressStateOne,
    //       street: carrierAltAddressStreetOne,
    //       zipcode: carrierAltAddressZipOne,
    //     },
    //     {
    //       active: true,
    //       addressLine1: "",
    //       addressLine2: "",
    //       archieved: true,
    //       alternateAddress: true,
    //       city: carrierAltAddressCityTwo,
    //       country: carrierAltAddressCountryTwo,
    //       deleted: false,
    //       state: carrierAltAddressStateTwo,
    //       street: carrierAltAddressStreetTwo,
    //       zipcode: carrierAltAddressZipTwo,
    //     },
    //   ],
    //   phoneCountryCode: phoneCountryCode,
    //   altPhoneCountryCode: AltPhoneCountryCode,
    //   faxCountryCode: faxCountryCode,
    //   alternatePhoneNumber: altMobileWithFlag?.toString().replace(/[^0-9]/g, ""),
    //   carrierName: carrierName,
    //   client: {
    //     clientId: "string",
    //     clientName: "string",
    //     id: 0,
    //   },
    //   currencyCode: carrierCurrencyCode,
    //   deleted: false,
    //   email: carrierEmail,
    //   endDate: carrierEndaDate,
    //   fax: faxWithFlag?.toString().replace(/[^0-9]/g, ""),
    //   language: carrierLanguage,
    //   notes: "",
    //   phoneNumber: mobileWithFlag?.toString().replace(/[^0-9]/g, ""),
    //   timezone: carrierTimeZone,
    // };

    let tabName: any = ContactType.CARRIERS;
    let [showPhoneError, setPhoneError] = useState(false);
    let [showPhoneErrorMsg, setPhoneErrorMsg] = useState(
        'Please select Country code for Phone number'
    );
    const handleAddCarrier = () => {
        console.log('add');
        // var phone = document.getElementById("txtPhone") as HTMLInputElement;
        // var countryCodephone = document.getElementById(
        //   "txtCountryCodePhone"
        // ) as HTMLSelectElement;
        // var altPhone = document.getElementById("txtAltPhone") as HTMLInputElement;
        // var countryCodeAltPhone = document.getElementById(
        //   "txtCountryCodeAltPhone"
        // ) as HTMLSelectElement;
        // var fax = document.getElementById("txtFax") as HTMLInputElement;
        // var countryCodeFax = document.getElementById(
        //   "txtCountryCodeFax"
        // ) as HTMLSelectElement;
        // if(phone.value !== '' && countryCodephone.value === ''){
        //   setPhoneError(true);
        //   setPhoneErrorMsg('Please select Country code for Phone number')
        //   errors['phoneCountryCodeError'] = 'Please select Country code for Phone number'
        //   phoneCountryCodeError = "Please select Country code for Phone number";
        // }
        // else if(altPhone.value !== '' && countryCodeAltPhone.value === ''){
        // altPhoneCountryCodeError = "Please select Country code for Alt Phone number";
        // }
        // else if(fax.value !== '' && countryCodeFax.value === ''){
        //  faxCountryCodeError = "Please select Country code for Fax";
        // }
        // else{
        // if (validate()) {
        const endpoint = addMasterDataEndPoint(tabName);
        const getEndpoint = getEndPoint(tabName);
        console.log(carrierInfo);
        console.log(carrierInfoSelectFieldObj);

        handleMasterDataChange(endpoint, getEndpoint);
        // }
        // }
    };

    const handleMasterDataChange = async (
        endpoint: string,
        getEndpoint: string
    ) => {

      addressInfo.forEach((element: any) => {
        element.alternateAddress = true;
        element.active = true;
        element.archieved = true;

    });

        const reqbody = {
            active: true,
            address: [
              {
                active: true,
                archieved: true,
                addressLine1: '',
                addressLine2: '',
                alternateAddress: false,
                city: primaryAddress.city,
                country: primaryAddress?.country,
                deleted: false,
                state: primaryAddress?.state,
                street: primaryAddress?.street,
                zipcode: primaryAddress?.zipcode,
                // billingAddress: primaryAddress?.billingAddress,
            },
            ...addressInfo,
                // {
                //     active: true,
                //     addressLine1: '',
                //     addressLine2: '',
                //     archieved: true,
                //     alternateAddress: false,
                //     city: carrierAddressCity,
                //     country: carrierAddressCountry,
                //     deleted: false,
                //     state: carrierAddressState,
                //     street: carrierAddressStreet,
                //     zipcode: carrierAddressZip,
                // },
                // {
                //     active: true,
                //     addressLine1: '',
                //     addressLine2: '',
                //     archieved: true,
                //     alternateAddress: true,
                //     city: carrierAltAddressCityOne,
                //     country: carrierAltAddressCountryOne,
                //     deleted: false,
                //     state: carrierAltAddressStateOne,
                //     street: carrierAltAddressStreetOne,
                //     zipcode: carrierAltAddressZipOne,
                // },
                // {
                //     active: true,
                //     addressLine1: '',
                //     addressLine2: '',
                //     archieved: true,
                //     alternateAddress: true,
                //     city: carrierAltAddressCityTwo,
                //     country: carrierAltAddressCountryTwo,
                //     deleted: false,
                //     state: carrierAltAddressStateTwo,
                //     street: carrierAltAddressStreetTwo,
                //     zipcode: carrierAltAddressZipTwo,
                // },
            ],
            phoneCountryCode: phoneCountryCode,
            altPhoneCountryCode: AltPhoneCountryCode,
            faxCountryCode: faxCountryCode,
            alternatePhoneNumber: altMobileWithFlag
                ?.toString()
                .replace(/[^0-9]/g, ''),
            carrierName: carrierInfo[0].carrierName,
            client: {
                clientId: 'string',
                clientName: 'string',
                id: 0,
            },
            currencyCode: carrierInfoSelectFieldObj[0].currencyCode,
            deleted: false,
            email: carrierInfo[0].email,
            endDate: carrierInfo[0].endDate,
            fax: faxWithFlag?.toString().replace(/[^0-9]/g, ''),
            language: carrierInfoSelectFieldObj[0].language,
            notes: '',
            phoneNumber: mobileWithFlag?.toString().replace(/[^0-9]/g, ''),
            timezone: carrierInfoSelectFieldObj[0].timezone,
        };
        const result = await axios
            .post(endpoint, reqbody)
            .then((results: any) => {
                if (results && results.data) {
                    setAddScreen(false);
                    notify('Carrier created successfully', 'success');
                } else {
                    notify('Failed to create Carrier', 'error');
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    notify(error.response?.data?.message, 'error');
                }
            });
        const getresult = await axios.get(getEndpoint);
        dispatch(setCurrentContact(getresult.data));
    };
    const handleCancelCarrierPopup = () => {
        setAddScreen(false);
        dispatch(setMasterDataAddImage([]));
    };
    const validate = (field?: any, value?: any) => {
        if (carrierName === '') {
            nameError = 'Required Field';
            setMyNameCarrier('1px solid red');
        } else {
            setMyNameCarrier('');
        }
        const reg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (carrierEmail && reg.test(carrierEmail) === false) {
            emailError = 'Email is Invalid ';
        }
        if (carrierLanguage === '') {
            languageError = 'Language is required';
        }
        if (carrierCurrencyCode === '') {
            currencyCodeError = 'Currency code is required';
        }
        if (carrierTimeZone === '') {
            timezoneError = 'Timezone is required';
        }
        const phoneReg =
            /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

        if (carrierPhone && phoneReg.test(carrierPhone) === false) {
            phoneError = 'Phone Number is Invalid';
        }
        if (carrierAltPhone && phoneReg.test(carrierAltPhone) === false) {
            altPhoneError = 'Phone Number is Invalid';
        }

        if (carrierFax !== '') {
            var regFax = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if (!regFax.test(carrierFax)) {
                faxError = 'Fax is Invalid ';
            }
        }
        // if(carrierEmail==="") {
        //   emailError = "Email is required";
        // }
        // if(carrierPhone==="") {
        //   phoneError = "Phone number is required";
        // }
        if (carrierPhone !== '' && carrierCountryCodePhone === '') {
            phoneCountryCodeError = 'Country Code is required for phone';
        }
        if (carrierAltPhone !== '' && carrierCountryCodeAltPhone === '') {
            altPhoneCountryCodeError = 'Country Code is required for alt phone';
        }
        if (carrierFax !== '' && carrierCountryCodeFax === '') {
            faxCountryCodeError = 'Country Code is required for fax';
        }
        console.log(carrierAddressCity);
        console.log(carrierAddressCountry);
        if (carrierAddressCountry === '' && carrierAddressCity !== '') {
            countryError = 'Please select country';
        }
        if (carrierAddressCountry === '' && carrierAddressCity !== '') {
            countryError = 'Please select country';
        }
        if (
            emailError ||
            nameError ||
            phoneError ||
            phoneCountryCodeError ||
            countryError ||
            altPhoneError ||
            languageError ||
            currencyCodeError ||
            timezoneError ||
            zipError ||
            faxError ||
            zipErrorOne ||
            zipErrorTwo
        ) {
            setErrors({
                nameError,
                emailError,
                phoneError,
                altPhoneCountryCodeError,
                countryError,
                faxCountryCodeError,
                phoneCountryCodeError,
                altPhoneError,
                languageError,
                currencyCodeError,
                timezoneError,
                zipError,
                faxError,
                zipErrorOne,
                zipErrorTwo,
            });
            setAddButtonDisabledCarrier(true);
            return false;
        } else {
            setAddButtonDisabledCarrier(false);
        }
        return true;
    };

    const handlePopupActionAddCarrier = (selectedTabName: any) => {
        uploadedImageFile = [];
        setFileUploadAddScreen(true);
        setAddScreenTabName(selectedTabName);
    };

    let uploadedImageFile = useSelector(
        (state: RootState) => state.contactReducer.uploadedImage
    );

    const [addScreen, setFileUploadAddScreen] = useState(false);
    const [addScreenTabName, setAddScreenTabName] = useState(null);
    const [screenType, setScreenType] = useState('masterdataAdd');

    let [stepperindexvalue, setStepperIndexValue] = useState(1);

    const goToNextStep = (index: number) => {
        console.log(carrierInfo);
        console.log(carrierInfoSelectFieldObj);
        if (index === 1) {
            setStepperIndexValue(1);
        } else if (index === 2) {
            setStepperIndexValue(2);
        }
    };

    return (
        <div className="commodity-popup" style={{ marginRight: '0' }}>
            <div className="StepperControl">
                <div className="col-6 pl-2 pt-0 mt-auto mb-auto">
                    <div className="page-header--col col--steps">
                        <ul className="list--steps pt-3 mb-0 pointer">
                            <li
                                className={` ${
                                    stepperindexvalue === 1 ? 'active' : ''
                                }`}
                                onClick={(e) => goToNextStep(1)}
                            >
                                <strong className="steps--number">1</strong>
                                <p className="steps--label"></p>
                            </li>
                            <li
                                className={` ${
                                    stepperindexvalue === 2 ? 'active' : ''
                                }`}
                                onClick={(e) => goToNextStep(2)}
                            >
                                <strong className="steps--number">2</strong>
                                <p className="steps--label"></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                {addScreen && (
                    <AddFileUploadScreen
                        addScreen={addScreen}
                        setFileUploadAddScreen={setFileUploadAddScreen}
                        tabName={addScreenTabName}
                        screenType={screenType}
                    />
                )}
                <div className="row d-flex justify-content-center">
                    <div className="col-xl-2 col-lg-2 roundImage">
                        {/* <input accept="image/*" type="file" onChange={imageChange} /> */}
                        {/* <img src={userIcon} className="userAddIcon" />
          {selectedImage && (
            <div className="imagePreviewDiv">
              <img
                src={URL.createObjectURL(selectedImage)}
                className="imagePreview"
                height="100"
                width="100"
              />
            </div>
          )} */}
                        {uploadedImageFile && uploadedImageFile.length > 0 ? (
                            <div className="ImageContainer">
                                <img
                                    src={URL.createObjectURL(
                                        uploadedImageFile[0].file
                                    )}
                                    className="userAddIcon"
                                    height="140"
                                    width="140"
                                />
                            </div>
                        ) : (
                            <img src={userIcon} className="userAddIcon" />
                        )}
                        <div className="middle">
                            <img
                                src={editIcon}
                                alt="edit "
                                width="18"
                                height="18"
                                className="m-3 pointer avatarEdit"
                                onClick={() =>
                                    handlePopupActionAddCarrier('fileUpload')
                                }
                                style={{ position: 'sticky' }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {stepperindexvalue === 1 ? (
                    <div>
                        <div className="col-sm-12 row m-0 mt-3">
                            <div className="col-sm-6">
                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{
                                            float: 'left',
                                            marginTop: '8px',
                                        }}
                                    >
                                        Name
                                        <span className="requiredStar">*</span>
                                    </span>
                                    {carrierInfo[0].carrierName === '' ? (
                                        <span
                                            className="text-danger"
                                            style={{
                                                float: 'right',
                                                marginTop: '8px',
                                            }}
                                        >
                                            {errors.nameError}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                    <input
                                        type="text"
                                        className="formcontrol"
                                        id="txtCarrierName"
                                        onChange={(e) =>
                                            handleCarrierTextFields(
                                                e,
                                                'carrierName'
                                            )
                                        }
                                        value={carrierInfo[0].carrierName}
                                        style={{ border: myCarrierName }}
                                    />
                                </div>

                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{
                                            float: 'left',
                                            marginTop: '8px',
                                        }}
                                    >
                                        Email
                                    </span>
                                    {emailErrorMsg !== '' ? (
                                        <span
                                            className="text-danger mt-3"
                                            style={{ float: 'right' }}
                                        >
                                            {emailErrorMsg}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="someone@email.com"
                                        onChange={(e) =>
                                            handleCarrierTextFields(e, 'email')
                                        }
                                        onBlur={(e) =>
                                            handleCarrierTextFields(e, 'email')
                                        }
                                        onKeyUp={(e) =>
                                            handleCarrierTextFields(e, 'email')
                                        }
                                        value={carrierInfo[0].email}
                                    />
                                </div>

                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{
                                            float: 'left',
                                            marginTop: '8px',
                                        }}
                                    >
                                        End Date
                                    </span>
                                    <input
                                        type="date"
                                        className="form-control"
                                        onChange={(e) =>
                                            handleCarrierTextFields(
                                                e,
                                                'endDate'
                                            )
                                        }
                                        value={carrierInfo[0].endDate}
                                    />
                                    {/* <DatePickerComponent /> */}
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{
                                            textAlign: 'left',
                                            marginTop: '8px',
                                            float: 'left',
                                        }}
                                    >
                                        Address
                                    </span>

                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Street"
                                        onChange={(e) =>
                                            handleAddressField(
                                                e.target.value,
                                                'street',
                                                -1
                                            )
                                        }
                                        value={primaryAddress?.street}
                                    />
                                    <div className=" mt-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="City"
                                            onChange={(e) =>
                                                handleAddressField(
                                                    e.target.value,
                                                    'city',
                                                    -1
                                                )
                                            }
                                            value={primaryAddress?.city}
                                        />
                                    </div>

                                    <div className="mt-3">
                                        <select
                                            className="form-control"
                                            value={primaryAddress?.country}
                                            onChange={(e: any) =>
                                                handleAddressField(
                                                    e.target.value,
                                                    'country',
                                                    -1
                                                )
                                            }
                                        >
                                            <option value="">
                                                Select Country
                                            </option>
                                            {countryInfo?.map(
                                                (cn: any, idx: number) => (
                                                    <option
                                                        key={idx}
                                                        value={cn.countryName}
                                                    >
                                                        {cn.countryName}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-8 mt-3">
                                            <select
                                                className="form-control"
                                                value={primaryAddress?.state}
                                                onChange={(e: any) =>
                                                    handleAddressField(
                                                        e.target.value,
                                                        'state',
                                                        -1
                                                    )
                                                }
                                            >
                                                <option value="">
                                                    Select State
                                                </option>
                                                {statesList?.map((cn: any) => (
                                                    <option
                                                        value={cn.stateName}
                                                    >
                                                        {cn.stateName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div
                                            className="col-sm-4 mt-3"
                                            style={{ paddingLeft: '0px' }}
                                        >
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="ZIP"
                                                onChange={(e) =>
                                                    handleAddressField(
                                                        e.target.value,
                                                        'zipcode',
                                                        -1
                                                    )
                                                }
                                                value={primaryAddress?.zipcode}
                                            />
                                        </div>

                                        
                                    </div>
                                    {addressInfo?.length < 2 && (
                                        <div className="d-flex align-items-center mt-3 cursor-pointer">
                                            <CiCirclePlus
                                                onClick={() => addAltAddress()}
                                                size={20}
                                            />{' '}
                                            <span
                                                onClick={() => addAltAddress()}
                                                style={{
                                                    marginLeft: 5,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Alt Address
                                            </span>
                                        </div>
                                    )}

                                    {addressInfo?.length > 0 && (
                                        <div
                                            style={{
                                                height: 160,
                                                overflowX: 'hidden',
                                                overflowY: 'scroll',
                                                paddingRight: 10,
                                                paddingLeft: 5,
                                                marginBottom: 10,
                                            }}
                                        >
                                            {addressInfo.map(
                                                (
                                                    address: any,
                                                    index: number
                                                ) => (
                                                    <>
                                                        <div className="alt-address-style">
                                                            <span>
                                                                Alt Address{' '}
                                                                {index + 1}
                                                            </span>

                                                            <RxCross2
                                                                size={20}
                                                                onClick={() =>
                                                                    removeAltAddress(
                                                                        address
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Street"
                                                            onChange={(e) =>
                                                                handleAddressField(
                                                                    e.target
                                                                        .value,
                                                                    'street',
                                                                    index
                                                                )
                                                            }
                                                            value={
                                                                address?.street
                                                            }
                                                        />
                                                        <div className=" mt-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="City"
                                                                onChange={(e) =>
                                                                    handleAddressField(
                                                                        e.target
                                                                            .value,
                                                                        'city',
                                                                        index
                                                                    )
                                                                }
                                                                value={
                                                                    address?.city
                                                                }
                                                            />
                                                        </div>

                                                        <div className="mt-3">
                                                            <select
                                                                id="ddlCountry"
                                                                className="form-control"
                                                                value={
                                                                    address?.country
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    handleAddressField(
                                                                        e.target
                                                                            .value,
                                                                        'country',
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <option value="">
                                                                    Select
                                                                    Country
                                                                </option>
                                                                {countryInfo?.map(
                                                                    (
                                                                        cn,
                                                                        idx: number
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                idx
                                                                            }
                                                                            value={
                                                                                cn.countryName
                                                                            }
                                                                        >
                                                                            {
                                                                                cn.countryName
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-8 mt-3">
                                                                <select
                                                                    className="form-control"
                                                                    value={
                                                                        address?.state
                                                                    }
                                                                    onChange={(
                                                                        e: any
                                                                    ) =>
                                                                        handleAddressField(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            'state',
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <option value="">
                                                                        Select
                                                                        State
                                                                    </option>
                                                                    {address?.stateList?.map(
                                                                        (
                                                                            cn: any
                                                                        ) => (
                                                                            <option
                                                                                value={
                                                                                    cn.stateName
                                                                                }
                                                                            >
                                                                                {
                                                                                    cn.stateName
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                            </div>

                                                            <div
                                                                className="col-sm-4 mt-3"
                                                                style={{
                                                                    paddingLeft:
                                                                        '0px',
                                                                }}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="ZIP"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleAddressField(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            'zipcode',
                                                                            index
                                                                        )
                                                                    }
                                                                    value={
                                                                        address?.zipcode
                                                                    }
                                                                />
                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 px-0 mt-3">
                            <ButtonComponent
                                cssClass="eg-btn-primary float-right pt-3 pb-3 pl-5 pr-5"
                                onClick={() => goToNextStep(2)}
                                style={
                                    carrierInfo[0].carrierName !== '' &&
                                    emailErrorMsg === ''
                                        ? {
                                              opacity: '1',
                                              pointerEvents: 'all',
                                              cursor: 'pointer',
                                          }
                                        : {
                                              opacity: '0.5',
                                              pointerEvents: 'none',
                                              backgroundColor: '#e8e8e8',
                                              color: '#969696',
                                          }
                                }
                            >
                                Next
                            </ButtonComponent>
                            <ButtonComponent
                                cssClass="eg-btn-primary-link e-flat mt-3"
                                onClick={() => handleCancelCarrierPopup()}
                            >
                                Cancel
                            </ButtonComponent>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="col-sm-12 row m-0 mt-3">
                            <div className="col-sm-6">
                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{
                                            float: 'left',
                                            marginTop: '8px',
                                        }}
                                    >
                                        Phone
                                    </span>
                                </div>
                                <div className="col-sm-12">
                                    <CountryDropdown type="Phone" />
                                    {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodePhone" onChange={(e: any)=>handleChangeCarrierCountryCodePhoneRC(e)}>
                  <option value="" >Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>

            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text" id="txtPhone" className="form-control" onChange={(e) => handleCarrierPhone(e)}
            onBlur={(e) => handleCarrierPhone(e)} onKeyUp={(e) => handleCarrierPhone(e)} />
            {phoneError ? <span className="text-danger">{errors.phoneError}</span> : ''}
            {errors.phoneError?<span className="text-danger">{errors.phoneError}</span>:""}
            </div> */}
                                    {errors.phoneCountryCodeError ? (
                                        <span className="text-danger">
                                            {errors.phoneCountryCodeError}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{
                                            float: 'left',
                                            marginTop: '8px',
                                        }}
                                    >
                                        Alt Phone
                                    </span>
                                </div>
                                <div className="col-sm-12">
                                    <CountryDropdown type="Alt Phone" />
                                    {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodeAltPhone" onChange={(e: any)=>handleChangeCarrierCountryAltPhoneRC(e)}
               onBlur={(e: any)=>handleChangeCarrierCountryAltPhoneRC(e)} onKeyUp={(e: any)=>handleChangeCarrierCountryAltPhoneRC(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text"  id="txtAltPhone" className="form-control" onChange={(e) => handleCarrierAltPhone(e)} />
             {errors.altPhoneError?<span className="text-danger">{errors.altPhoneError}</span>:""}

            </div> */}
                                    {errors.altPhoneCountryCodeError ? (
                                        <span className="text-danger">
                                            {errors.altPhoneCountryCodeError}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div className="col-sm-12">
                                    <span
                                        className="lblClass"
                                        style={{
                                            float: 'left',
                                            marginTop: '8px',
                                        }}
                                    >
                                        Fax
                                    </span>
                                </div>
                                <div className="col-sm-12">
                                    <CountryDropdown type="Fax" />
                                    {/* <div className="col-sm-4 pl-0 pr-0">
            <select className="form-control" id="txtCountryCodeFax" onChange={(e: any)=>handleChangeCarrierCountryCodeFaxRC(e)}>
                  <option value=''>Select</option>
                  {countryCodeInfo?.map((ld) =>
                    <option value={ld?.mobileCountryCode}>{ld?.mobileCountryCode}</option>
                  )}
            </select>
            </div>
            <div className="col-sm-8 pl-0 pr-0">
            <input type="text"  id="txtFax" className="form-control" onChange={(e) => handleCarrierFax(e)} />
            {errors.faxError?<span className="text-danger">{errors.faxError}</span>:""}
            </div> */}
                                    {errors.faxCountryCodeError ? (
                                        <span className="text-danger">
                                            {errors.faxCountryCodeError}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            <div>
                                <div className="col-sm-6">
                                    <div className="col-sm-12">
                                        <span
                                            className="lblClass"
                                            style={{
                                                float: 'left',
                                                marginTop: '8px',
                                            }}
                                        >
                                            Language
                                            <span className="requiredStar">
                                                *
                                            </span>
                                        </span>
                                        <select
                                            className="form-control"
                                            value={
                                                carrierInfoSelectFieldObj[0]
                                                    .language
                                            }
                                            onChange={(e: any) =>
                                                handleCarrierSelectFields(
                                                    e,
                                                    'language'
                                                )
                                            }
                                        >
                                            {languageData?.map((ld) => (
                                                <option value={ld?.isoLongCode}>
                                                    {ld?.isoLongCode}
                                                </option>
                                            ))}
                                        </select>
                                        {carrierLanguage === '' ? (
                                            <span className="text-danger">
                                                {errors.languageError}
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>

                                    <div className="col-sm-12">
                                        <span
                                            className="lblClass"
                                            style={{
                                                float: 'left',
                                                marginTop: '8px',
                                            }}
                                        >
                                            Currency Code
                                            <span className="requiredStar">
                                                *
                                            </span>
                                        </span>
                                        <select
                                            className="form-control"
                                            value={
                                                carrierInfoSelectFieldObj[0]
                                                    .currencyCode
                                            }
                                            onChange={(e: any) =>
                                                handleCarrierSelectFields(
                                                    e,
                                                    'currencyCode'
                                                )
                                            }
                                        >
                                            {currencyData?.map((cc) => (
                                                <option value={cc.currency}>
                                                    {cc.currency}
                                                </option>
                                            ))}
                                        </select>
                                        {carrierCurrencyCode === '' ? (
                                            <span className="text-danger">
                                                {errors.currencyCodeError}
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>

                                    <div className="col-sm-12">
                                        <span
                                            className="lblClass"
                                            style={{
                                                float: 'left',
                                                marginTop: '8px',
                                            }}
                                        >
                                            Time Zone
                                            <span className="requiredStar">
                                                *
                                            </span>
                                        </span>
                                        <select
                                            className="form-control"
                                            value={
                                                carrierInfoSelectFieldObj[0]
                                                    .timezone
                                            }
                                            onChange={(e: any) =>
                                                handleCarrierSelectFields(
                                                    e,
                                                    'timezone'
                                                )
                                            }
                                        >
                                            {timezoneData?.map((tz) => (
                                                <option value={tz.timezoneName}>
                                                    {tz.timezoneName}
                                                    {tz.utc}
                                                </option>
                                            ))}
                                        </select>
                                        {carrierTimeZone === '' ? (
                                            <span className="text-danger">
                                                {errors.timezoneError}
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="col-sm-12 px-0"
                            style={{ marginTop: '7rem' }}
                        >
                            <ButtonComponent
                                cssClass="eg-btn-primary float-right"
                                onClick={() => handleAddCarrier()}
                                style={
                                    isCarrierNextDisabeled
                                        ? {
                                              opacity: '0.5',
                                              pointerEvents: 'none',
                                              backgroundColor: '#e8e8e8',
                                              color: '#969696',
                                              borderRadius: '4px',
                                          }
                                        : {
                                              opacity: '1',
                                              borderRadius: '4px',
                                              pointerEvents: 'all',
                                              backgroundColor: '#636799',
                                              color: '#ffffff',
                                              cursor: 'pointer',
                                          }
                                }
                            >
                                ADD CARRIER
                            </ButtonComponent>
                            <ButtonComponent
                                cssClass="eg-btn-secondary float-right mr-4"
                                onClick={() => goToNextStep(1)}
                            >
                                Back
                            </ButtonComponent>
                            <ButtonComponent
                                cssClass="eg-btn-primary-link e-flat mt-3"
                                onClick={handleCancelCarrierPopup}
                            >
                                CANCEL
                            </ButtonComponent>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default CarrierAddScreen;
